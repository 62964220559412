@import "./styles/vars.scss";
@import "./styles/fonts.scss";
@import "./styles/material-theme.scss";
@import "./styles/common";
// @import "~ngx-org-chart/_theming.scss";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
