:root {

    --color : #4e5d5b;
    --background-color: #fff;
    --primary-color: #0d977d;

    --mainmenuitem-color : #777;
    --mainmenuitem-color-hover : #0d977d;
    --mainmenuitem-background-color : #fff;
    --mainmenuitem-background-color-hover : #fff;

    --menuitem-color:#fff;
    --menuitem-background-color: #4e5d5b;
    --menuitem--color-hover: #fff;
    --menuitem-background-color-hover:rgba(97,110,108,.7);

    --main-text-color: #333;
    --secondary-text-color: #777;
    --error-text-color: #f00;

    --even-table-background-color: #f5f5f5;

    // Dialogs
    --dialog-background-color : #eee;
    --dialog-header-background-color : #eee;
    --dialog-content-background-color : #fff;
    --dialog-footer-background-color : #eee;


}
