* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: $body-font;
  font-weight: normal;
  line-height: 1.3;
  color: var(--main-text-color);
}

li,
ul,
ol {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.warning-test {
  color: red;
}

.hotline {
  font: 600 14px/16px "Etelka", sans-serif;
  a {
    color: var(--primary-color);
  }
}

.dropdown-menu {
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    left: 50%;
    margin-left: -16px;
    border-left: 16px solid transparent;
    border-bottom: 10px solid #4e5d5b;
    border-right: 16px solid transparent;
  }
}

.container {
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (min-width: 1440px) {
    width: 1410px;
  }
  @media (min-width: 1600px) {
    width: 1570px;
  }
  @media (min-width: 1920px) {
    width: 1890px;
  }
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.margin-right {
  margin-right: 12px;
}

.right-align {
  text-align: right;
}
.left-align {
  text-align: right;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.display-flex {
  display: flex;
}

.mat-sort-header-container {
  justify-content: center;
}

.mat-table {
  border-collapse: collapse;

  .mat-header-cell,
  .mat-cell {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-header-cell {
    font-size: 14px;
    color: var(--main-text-color);
    text-align: center;
    background: #ceefe7;
    border-color: #a6cac1;
  }
  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell,
  td.mat-cell {
    padding: 0 4px;
  }
  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 4px;
  }
  tr:nth-child(even) {
    td {
      background: var(--even-table-background-color);
    }
  }
}

.user-message {
  padding: 16px 22px;
  border-bottom: 1px solid #eee;
}

/* -> Page */

.page-header {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;

  .page-title {
    display: none;
    visibility: hidden;
    h2 {
      font: 600 20px/25px "Etelka", sans-serif;
      color: var(--primary-color);
      margin: 1rem 0;
      text-align: left;
    }
  }
}

.page-actions {
  font: 600 16px/18px "Etelka", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  .detailed-mode-info {
    .detailed-mode-info-title {
      padding-right: 16px;
    }
  }
}

.page-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .toolbar-section-left {
    display: flex;
    align-items: baseline;
    justify-content: left;
  }
  .toolbar-section-rigth {
    display: flex;
    align-items: baseline;
  }
}

/* <- Page */

/* Org Chart */
.svg-chart-container {
  pointer-events: none;
  touch-action: none;
}

.contragent-wrapper {
  padding: 24px;
  font-size: 28px;
  color: #777;
  background-color: #ceefe7;
  border-radius: 15px;
  .contragent-image {
    width: 100px;
    height: 100px;
    position: relative;
    left: -75px;
    top: -75px;
    margin-bottom: -100px;
  }
  .contragent {
    color: #777;
    padding: 8px 0px 8px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .contragent-caption {
      padding-left: 24px;
      &::after {
        content: "Контрагент:";
      }
    }
    .contragent-name {
      padding-left: 8px;
      white-space: nowrap;
      font-weight: bold;
      text-overflow: ellipsis;
    }
  }
  .contragent-contact-persons-wrapper {
    margin: -23px;
    padding: 22px;
    margin-top: 23px;
    background-color: #eee;
    font-size: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .contragent-contact-persons-caption {
      text-align: center;
      &::after {
        content: "Контактні особи:";
      }
    }
    .contact-person {
      padding-top: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .contact-person-caption {
        display: inline-block;
        width: 170px;
        text-align: right;
        &::after {
          content: "Представник:";
        }
      }
      .contact-person-name {
        font-weight: bold;
      }
    }
    .contact-person-phones {
      .contact-person-phones-caption {
        font-weight: normal;
      }
      .contact-person-phone {
        .contact-person-phone-caption {
          display: inline-block;
          width: 170px;
          text-align: right;
          &::after {
            content: "Тел.:";
          }
        }
        a {
          font-style: normal;
          color: #777;
        }
      }
    }
  }
  .contragent-status {
    padding-top: 36px;
  }
}

.company-wrapper {
  padding: 24px;
  font-size: 28px;
  color: #777;
  background-color: #ceefe7;
  border-radius: 15px;
  .company-image {
    width: 100px;
    height: 100px;
    position: relative;
    left: -75px;
    top: -75px;
    margin-bottom: -100px;
  }
  .company {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 8px 0px 8px 0px;
    overflow: hidden;
    .company-caption {
      padding-left: 24px;
      &::after {
        content: "Юр.особа:";
      }
    }
    .company-name {
      padding-left: 8px;
      font-weight: bold;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .company-info {
    // display: none;
    .company-info-title {
      width: 70px;
    }
    // .company-info-supplierStatus {
    // }
    .company-info-area {
      .company-info-area-title {
        padding-left: 22px;
      }
      .company-info-area-value {
        font-weight: bold;
      }
    }
  }

  .company-contact-persons-wrapper {
    padding-top: 8px;
    margin: -23px;
    padding: 22px;
    margin-top: 23px;
    background-color: #eee;
    font-size: 26px;
    // .company-contact-persons-list{
    //   max-width: 300px;
    //   overflow: auto;
    // }
    .company-contact-persons-caption {
      text-align: center;
      &::after {
        content: "Контактні особи:";
      }
    }
    .contact-person {
      padding-top: 16px;
      .contact-person-caption {
        display: inline-block;
        width: 170px;
        text-align: right;
        &::after {
          content: "Представник:";
        }
      }
      .contact-person-name {
        font-weight: bold;
      }
    }
    .contact-person-phones {
      .contact-person-phones-caption {
        font-weight: normal;
      }
      .contact-person-phone {
        .contact-person-phone-caption {
          display: inline-block;
          width: 170px;
          text-align: right;
          &::after {
            content: "Тел.:";
          }
        }
        a {
          font-style: normal;
          color: #777;
        }
      }
    }
  }
}

.cdk-overlay-container {
  .mat-dialog-container {
    background-color: var(--dialog-background-color);

    .mat-dialog-title {
      display: flex;
      justify-content: space-between;
      background-color: var(--dialog-header-background-color);
      padding: 0;
      margin: 0;
      .close-button {
        margin-right: -18px;
        margin-top: -4px;
      }
    }
  }
  .mat-dialog-content {
    padding: 16px 0;
    background-color: var(--dialog-content-background-color);
  }
  .mat-dialog-actions {
    display: flex;
    justify-content: space-between;
  }
}

.pictures-wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // grid-template-rows: auto;
  .picture-wrapper {
    img {
      max-width: 240px;
      height: auto;
    }
  }
}

.no-data {
  display: flex;
  flex:1;
  justify-content: center;
  padding-top: 80px;
  font-size: 2rem;
  font-weight: bold;
}
