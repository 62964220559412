// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800);
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
  url(/assets/fonts/MaterialIconsOutlined-Regular.otf) format("otf");
}
@font-face {
  font-family: "Etelka";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/Etelka-Light-Pro.ttf);
}

$open-sans: 'Open Sans', sans-serif;
$body-font: 'Open Sans', sans-serif;
